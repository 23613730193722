import {useState} from 'react'

import VideoOptions from './videoOptions'
import HTMLGenerated from './htmlGenerated'

function Main (){
    const [source, setSource] = useState('youtube')
    const [displayOption, setDisplayOption] = useState('same')

    const [mobileURL, setMobileURL] = useState('dQw4w9WgXcQ')
    const [mobileURLWidth, setMobileURLWidth] = useState('720')
    const [mobileURLHeight, setMobileURLHeight] = useState('1280')
    const [mobileURLQuery, setMobileURLQuery] = useState('?')

    const [desktopURL, setDesktopURL] = useState('dQw4w9WgXcQ')
    const [desktopURLWidth, setDesktopURLWidth] = useState('1280')
    const [desktopURLHeight, setDesktopURLHeight] = useState('720')
    const [desktopURLQuery, setDesktopURLQuery] = useState('?')

    return (
        <main>
            <VideoOptions 
                source={source} setSource={setSource} displayOption={displayOption} setDisplayOption={setDisplayOption}
                mobileURL={mobileURL} mobileURLWidth={mobileURLWidth} mobileURLHeight={mobileURLHeight}
                setMobileURL={setMobileURL} setMobileURLWidth={setMobileURLWidth} setMobileURLHeight={setMobileURLHeight}
                desktopURL={desktopURL} desktopURLWidth={desktopURLWidth} desktopURLHeight={desktopURLHeight}
                setDesktopURL={setDesktopURL} setDesktopURLWidth={setDesktopURLWidth} setDesktopURLHeight={setDesktopURLHeight}
                mobileURLQuery={mobileURLQuery} setMobileURLQuery={setMobileURLQuery}
                desktopURLQuery={desktopURLQuery} setDesktopURLQuery={setDesktopURLQuery} />
            <HTMLGenerated 
                source={source} displayOption={displayOption}
                mobileURL={mobileURL} mobileURLWidth={mobileURLWidth} mobileURLHeight={mobileURLHeight}
                desktopURL={desktopURL} desktopURLWidth={desktopURLWidth} desktopURLHeight={desktopURLHeight}
                mobileURLQuery={mobileURLQuery} desktopURLQuery={desktopURLQuery} />
        </main>
    )
}

export default Main