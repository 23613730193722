
import SimpleInput from './simpleInput'
import SelectInput from './selectInput'

function VideoOptions ({
    source, setSource, 
    displayOption, setDisplayOption,
    mobileURL, mobileURLWidth, mobileURLHeight,
    setMobileURL, setMobileURLWidth, setMobileURLHeight,
    desktopURL, desktopURLWidth, desktopURLHeight,
    setDesktopURL, setDesktopURLWidth, setDesktopURLHeight,
    mobileURLQuery, setMobileURLQuery, desktopURLQuery, setDesktopURLQuery
}){    

    const showMobileUrlOption = displayOption === "only-mobile" || displayOption === "desktop-mobile"
    const showDesktopUrlOption = displayOption !== "only-mobile"

    return (
        <div className='video-options-container'>
            <SelectInput
                options={[
                    { label: "Youtube", value: "youtube" }, 
                    { label: "Vimeo", value: "vimeo" }
                ]}
                name="source"
                label="Select the source for the video: "
                value={source}
                onChange={(e) => setSource(e.target.value)} />
            <SelectInput
                options={[
                    { label: "Only Desktop", value: "only-desktop" }, 
                    { label: "Only Mobile", value: "only-mobile" }, 
                    { label: "Desktop and Mobile", value: "desktop-mobile" }, 
                    { label: "Same video for Desktop and Mobile", value: "same" }, 
                ]}
                name="display-option"
                label="Select the display option: "
                value={displayOption}
                onChange={(e) => setDisplayOption(e.target.value)} />
            {showDesktopUrlOption && <hr /> }           
            {showDesktopUrlOption && (
                <div>
                    <strong>Desktop</strong>
                    <br /><br />
                    <SimpleInput name="url-desktop" label="Video ID" value={desktopURL} onChange={(e) => setDesktopURL(e.target.value)} />
                    <SimpleInput name="url-desktop-width (px)" label="Video Width" value={desktopURLWidth} onChange={(e) => setDesktopURLWidth(e.target.value)} />
                    <SimpleInput name="url-desktop-height (px)" label="Video Height" value={desktopURLHeight} onChange={(e) => setDesktopURLHeight(e.target.value)} />
                    <SimpleInput name="url-desktop-query" label="Video Query" value={desktopURLQuery} onChange={(e) => setDesktopURLQuery(e.target.value)} />
                </div>
            )}
            {showMobileUrlOption && <hr /> }
            {showMobileUrlOption && (
                <div>
                    <strong>Mobile</strong>
                    <br /><br />
                    <SimpleInput name="mobile-url" label="Video ID" value={mobileURL} onChange={(e) => setMobileURL(e.target.value)} />
                    <SimpleInput name="url-mobile-width" label="Video Width (px)" value={mobileURLWidth} onChange={(e) => setMobileURLWidth(e.target.value)} />
                    <SimpleInput name="url-mobile-height" label="Video Height (px)" value={mobileURLHeight} onChange={(e) => setMobileURLHeight(e.target.value)} />
                    <SimpleInput name="url-mobile-query" label="Video Query" value={mobileURLQuery} onChange={(e) => setMobileURLQuery(e.target.value)} />
                </div>
            )}
        </div>
    )
}

export default VideoOptions