import Header from './components/header';
import MainComponent from './components/main';
import Footer from './components/footer';
import './index.css';

function App() {
  return (
    <div className="App">
      <Header />
      <MainComponent />
      <Footer />
    </div>
  );
}

export default App;
