
function SelectInput({ options, name, value, onChange, label }){
    return (
        <div className="select-input">
            <label>{label}</label>
            <select
                value={value}
                name={name}
                onChange={onChange}>
                {options.map(({value, label}, idx)=>(<option key={idx} value={value}>{label}</option>))}
            </select>
        </div>
    )
}

export default SelectInput