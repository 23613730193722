
function Footer (){
    return (
        <footer>
            <div>
                <span>Do you have any suggestions to improve this app? Send to <a href="mailto:mateus@amplieecro.com.br">mateus@amplieecro.com.br</a></span>
            </div>
            <div>
                <span>&copy; {new Date().getFullYear()} 
                &nbsp;-
                Created by <a href="https://www.amplieecro.com.br" target="_blank" rel="noreferrer">Mateus Machado</a></span>
            </div>
        </footer>
    )
}

export default Footer