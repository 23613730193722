import styles from '../css/styles.module.css'

function generateStyles(_id,displayOption){
    switch(displayOption){
        case 'only-desktop':
            return `<style>
                @media screen and (max-width: 767px){
                    .only-desktop-${_id} { display: none; }
                }                
            </style>`;
        case 'only-mobile':
            return `<style>
                @media screen and (min-width: 767px){
                    .only-mobile-${_id} { display: none; }
                }                
            </style>`;
        case 'desktop-mobile':
            return `<style>
                @media screen and (max-width: 767px){
                    .only-desktop-${_id} { display: none; }
                } 
                @media screen and (min-width: 767px){
                    .only-mobile-${_id} { display: none; }
                }                
            </style>`;
        default:
            return '';
    }       
}

function generateVideo(source, url, width, height, type, query){
    if(!url) return '';
    if(source === 'vimeo'){
        return (
            <div>
                <span>&nbsp;&nbsp;{"<iframe "}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    src="https://player.vimeo.com/video/'+ url + query +'"'}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    frameborder="0"'}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    allow="autoplay; fullscreen; picture-in-picture"'}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    allowfullscreen loading="lazy" '}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    style="position:absolute;top:0;left:0;width:100%;height:100%;">'}</span>
                <span>&nbsp;&nbsp;{"</iframe>"}</span>
            </div>
        )
    } else if(source==="youtube"){
        return (
            <div>
                <span>&nbsp;&nbsp;{"<iframe "}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;"'}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    width="'+ (width || type === "desktop" ? '1280' : '720') + '" '}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    height="'+ (height || type === "desktop" ? '720' : '1280') + '" '}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    title="YouTube video player"'}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    frameborder="0" '}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" '}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    allowfullscreen loading="lazy" '}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{'    src="https://www.youtube.com/embed/' + url + query+'" >'}</span>

                <span>&nbsp;&nbsp;{"</iframe>"}</span>
            </div>
        );
    } 
}

function generateWrapperHTML(id, videoHTML, displayOption, width, height, type){
    if(type === "desktop" && displayOption === "only-mobile") return null;
    if(type === "mobile" && (displayOption === "only-desktop" || displayOption === "same")) return null;
    const proportion = ((height / width) * 100).toFixed(2)
    return (
        <div>
            <span>{'<div style="margin: 0 auto; max-width: 1100px;" class="only-' + type +'-' + id +'">'}</span>
            <span>&nbsp;&nbsp;{'<div style="position: relative; overflow: hidden; width: 100%; padding-top: ' + proportion + '%; margin-bottom: 10px;">'}</span>
            <span>{videoHTML}</span>
            <span>&nbsp;&nbsp;{'</div>'}</span>
            <span>{'</div>'}</span>
        </div>
    )
}

function generateExtraCode(source){
    return source === 'vimeo'? (
        <div>
            <span>{'<script src="https://player.vimeo.com/api/player.js"></script>'}</span>
        </div>
    ): null
}

async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
}

function HTMLGenerated({
    source, displayOption,
    mobileURL, mobileURLWidth, mobileURLHeight,
    desktopURL, desktopURLWidth, desktopURLHeight,
    mobileURLQuery, desktopURLQuery
}){

    const _id = Date.now().toString().slice(-5)

    const htmlStyles = generateStyles(_id,displayOption)

    const mobileVideo = generateVideo(source, mobileURL, mobileURLWidth, mobileURLHeight, 'mobile', mobileURLQuery)
    const mobileWrapper = generateWrapperHTML(_id, mobileVideo, displayOption, mobileURLWidth, mobileURLHeight, 'mobile')

    const desktopVideo = generateVideo(source, desktopURL, desktopURLWidth, desktopURLHeight, 'desktop', desktopURLQuery)
    const desktopWrapper = generateWrapperHTML(_id, desktopVideo, displayOption, desktopURLWidth, desktopURLHeight, 'desktop')

    const extraCode = generateExtraCode(source)

    return (
        <div className={`html-generated-container`}>
            <span>HTML Generated</span>
            <br />
            <div className={`${styles.container} ${styles.htmlgenerated}`} id="html-generated">
                {htmlStyles || ""}
                <br />
                {"<div>"}
                {mobileWrapper}
                <br />
                {desktopWrapper}
                <br />
                {extraCode}
                {"</div>"}
            </div>
            <br />
            <div>
                <button className='copy-to-clipboard-btn' onClick={() => copyTextToClipboard(document.getElementById('html-generated').innerText)}>
                    Copy to Clipboard
                </button>
            </div>
        </div>
    )
}

export default HTMLGenerated