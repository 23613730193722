
function SimpleInput({ name, value, onChange, label }){
    return (
        <div className="simple-input">
            <label>{label}</label>
            <input name={name} value={value} onChange={onChange} />
        </div>
    )
}

export default SimpleInput